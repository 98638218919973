




































import rService from '@/services/RequestService/RequestServiceSetup';
import { mapGetters } from 'vuex';

export default {
  name: 'MyEntries',
  props: {},
  data() {
    return {
      showRelevant: true,
      entry: '',
      type: '',
      pagination: {},
      entries: 0,
      page: 0,
      loading: false,
      values: [],
      totalPages: 0,
      itemsPerPage: 15,
    };
  },
  components: {},
  watch: {},
  methods: {
    t(text: string) {
      return this.$vuetify.lang.t('$vuetify.Tracking.' + text);
    },
    timeAgo(date: string) {
      if (!date) return '';
      return this.$moment(date).fromNow();
    },
    processDate(date: string) {
      if (!date) return 'Unknown';
      return this.$moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    processChanges(changes) {
      return changes.join('<br>');
    },
    async init() {
      const entry = this.entry;
      const type = this.type;
      const res = await rService.post('tracking/history', { entry, type });
      if (res.error) {
        this.$router.push({ name: 'home' }).catch();
      } else {
        this.values = res;
      }
    },
  },
  computed: {
    valuesComputed() {
      if (this.showRelevant) {
        return this.values.filter((el) => {
          return el.found > 0;
        });
      }
      return this.values;
    },
    ...mapGetters(['userInfo']),
    headers() {
      return [
        { text: 'ID', value: 'id', width: '90px', sortable: false },
        { text: 'Creation Date', value: 'creation_date', width: 'auto', sortable: true },
        { text: 'Found', value: 'found', width: 'auto', sortable: true },
        { text: 'Changes', value: 'changes', width: 'auto', sortable: true },
        { text: 'Check', value: 'check', width: 'auto', sortable: false },
      ];
    },
  },
  created() {
    const params = this.$router.currentRoute.params;
    if (params && params.entry) {
      this.entry = params.entry;
      this.type = params.type;
    }
    this.init();
  },
};
